import { render, staticRenderFns } from "./Alcobaça.vue?vue&type=template&id=3711f7cc&scoped=true&"
import script from "./Alcobaça.vue?vue&type=script&lang=js&"
export * from "./Alcobaça.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3711f7cc",
  null
  
)

export default component.exports